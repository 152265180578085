import { GanttEventCallback, GanttStatic, Task } from '@blackhyve/dhtmlx-gantt';
import { parseDate } from '@blackhyve/utilities/dates';
import { addDays, endOfDay, Interval, isWithinInterval, startOfDay } from 'date-fns';

// Types
interface ResourceCounts {
  requiredResourceCount: number;
  allocatedResourceCount: number;
}

// Cache implementation
class Cache {
  private cache = new Map<string, any>();

  getCacheKey(taskId: string | number, date: Date): string {
    const dateStr = date.toISOString().split('T')[0];
    return `${taskId}:${dateStr}`;
  }

  get(taskId: string | number, date: Date): any {
    return this.cache.get(this.getCacheKey(taskId, date));
  }

  set(taskId: string | number, date: Date, value: any): void {
    this.cache.set(this.getCacheKey(taskId, date), value);
  }

  clear(): void {
    this.cache.clear();
  }
}

const assignedCountCache = new Cache();
const openRequestCache = new Cache();

// Utility functions
function isTaskWithinDateInterval(task: Task, date: Date): boolean {
  if (task.start_date && task.end_date) {
    return isWithinInterval(date, {
      start: startOfDay(task.start_date),
      end: endOfDay(new Date(+task.end_date - 1)),
    });
  } else {
    return false;
  }
}

// Main functions
export function getAssignedResourceRollup(
  gantt: GanttStatic,
  taskId: string | number,
  date: Date,
  processed: Set<string | number> = new Set()
): number {
  const cachedValue = assignedCountCache.get(taskId, date);
  if (cachedValue !== undefined) {
    return cachedValue;
  }

  const children = gantt.getChildren(taskId);
  if (!children?.length) return 0;

  const totalResourceCount = children.reduce((total, childId) => {
    if (processed.has(childId)) return total;
    processed.add(childId);

    const child = gantt.getTask(childId);
    let childResourceCount = 0;

    if (child.type !== 'resource_request' && isTaskWithinDateInterval(child, date)) {
      childResourceCount += 1;
    }

    if (gantt.hasChild(childId)) {
      childResourceCount += getAssignedResourceRollup(gantt, childId, date, processed);
    }

    return total + childResourceCount;
  }, 0);

  assignedCountCache.set(taskId, date, totalResourceCount);
  return totalResourceCount;
}

export function getOpenRequestCount(
  gantt: GanttStatic,
  taskId: string | number,
  date: Date,
  processed: Set<string | number> = new Set()
): ResourceCounts {
  // Check cache first
  const cachedValue = openRequestCache.get(taskId, date);
  if (cachedValue !== undefined) {
    return cachedValue;
  }

  const children = gantt.getChildren(taskId);
  const requiredResourceCount =
    children?.reduce((total, childId) => {
      if (processed.has(childId)) return total;
      processed.add(childId);

      const child = gantt.getTask(childId);
      if (child.type === 'resource_request' && isTaskWithinDateInterval(child, date)) {
        return total + (child.resource_count || 0);
      }
      return total;
    }, 0) || 0;

  const allocatedResourceCount = getAssignedResourceRollup(gantt, 'open-requests', date);

  const result = { requiredResourceCount, allocatedResourceCount };

  // Cache the result
  openRequestCache.set(taskId, date, result);

  return result;
}

export function attachCacheInvalidationEvents(gantt: GanttStatic): void {
  const invalidationEvents: (keyof GanttEventCallback)[] = [
    'onAfterTaskAdd',
    'onAfterTaskUpdate',
    'onAfterTaskDelete',
    'onTaskDrag',
    'onAfterTaskMove',
    'onAfterUndo',
    'onAfterRedo',
  ];

  const invalidateCache = (taskId: Task['id']) => {
    assignedCountCache.clear();
    openRequestCache.clear();
    gantt.eachParent((task) => gantt.refreshTask(task.id), taskId);
    return true;
  };

  invalidationEvents.forEach((event) => {
    gantt.attachEvent(event, invalidateCache);
  });
}
