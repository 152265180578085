import { GanttEventCallback, GanttStatic } from '@blackhyve/dhtmlx-gantt';
import { useModal } from 'components/common/ModalProvider';
import { useMemo } from 'react';
import { OPERATION_MODALS } from '../components/OperationModals';

// Define the event type with generic parameter
type GanttEvent<K extends keyof GanttEventCallback> = {
  event: K;
  callback: GanttEventCallback[K];
};

// Helper function to create properly typed events
function createGanttEvent<K extends keyof GanttEventCallback>(
  event: K,
  callback: GanttEventCallback[K]
): GanttEvent<K> {
  return { event, callback };
}

function refreshResources(gantt) {
  const resourceStore = gantt.getDatastore(gantt.config.resource_store);
  resourceStore.refresh();
}

type ActionHandlerParams = {
  gantt: GanttStatic;
  id: string | number;
};

export const useOperationBoardEvents = () => {
  //@ts-ignore
  const { openModal } = useModal();

  const actionHandlers = useMemo(
    () => ({
      add: ({ gantt, id }: ActionHandlerParams) => {
        const task = gantt.getTask(id);
        if (task.type === 'task') {
          return false;
        }

        openModal(OPERATION_MODALS.RESOURCE_AVAILABILITY_DIALOG, {
          open: true,
          gantt,
          resourceRequestId: id,
        });
        return false;
      },

      delete: ({ gantt, id }: ActionHandlerParams) => {
        gantt.confirm({
          title: 'Remove assignment',
          text: 'Are you sure you want to remove this resource assignment?',
          callback: (res) => res && gantt.deleteTask(id),
        });
        return false;
      },

      save: ({ gantt, id }: ActionHandlerParams) => {
        openModal(OPERATION_MODALS.RESOURCE_REQUEST_UPDATE_DIALOG, {
          open: true,
          gantt,
          id,
        });
        return false;
      },
    }),
    [openModal]
  );

  const events = useMemo(
    () => [
      createGanttEvent(
        'onTaskClick',
        function (this: GanttStatic, id: string | number, event: Event) {
          const button = (event.target as HTMLElement).closest('[data-action]');
          if (!button) {
            return true;
          }

          const action = button.getAttribute('data-action') as keyof typeof actionHandlers;
          const handler = actionHandlers[action];
          if (handler) {
            return handler({ gantt: this, id });
          }

          return true;
        }
      ),

      createGanttEvent('onTaskDrag', function (this: GanttStatic) {
        const gantt = this;
        refreshResources(gantt);
      }),

      createGanttEvent('onAfterTaskDrag', function (this: GanttStatic) {
        const gantt = this;
        refreshResources(gantt);
      }),

      createGanttEvent('onBeforeTaskDisplay', function (this: GanttStatic, taskId) {
        if (taskId === 'closed-requests' || this.isChildOf(taskId, 'closed-requests')) {
          return false;
        } else {
          return true;
        }
      }),
    ],
    [actionHandlers]
  );

  return events;
};
