import { Task } from '@blackhyve/dhtmlx-gantt';

export interface ResourceAssignment {
  id: number;
  task_id: number;
  resource_id: number;
  value?: number;
}

export interface TradeRole {
  id?: number;
  trade_id: string;
  trade_name?: string;
  role_id?: string;
  role_name?: string;
}

export interface Resource {
  id: number;
  name: string;
  type: ResourceType;
  unit?: string;
  max_capacity?: number;
  trades?: TradeRole[];
  workday_calendar?: any;
  parents: number[];
  trade_id?: any;
  role_id?: any;
}

export enum ResourceType {
  Labor = 'labor',
  Equipment = 'equipment',
  Group = 'group',
}

export enum ResourceUnit {
  Hours = 'hours',
}

export enum DemandCalculation {
  Sum = 'sum',
  Max = 'max',
}

export enum ResourceRequestStatus {
  Open = 'open',
  Closed = 'closed',
}

export interface Trade {
  id: number;
  name: string;
}

export interface Role {
  id: number;
  name: string;
}

export interface NewResourceProjectAssignment {
  id?: number;
  start_date: string | Date;
  end_date: string | Date;
  resource_request_id: number;
  resource_id: number;
}
export interface ResourceRequestAssignment {
  id: number;
  import_id?: number;
  start_date: string;
  end_date: string;
  resource_request_id: number;
  resource_id: number;
}
export interface ResourceRequestAssignmentTask extends Task {
  import_id?: string | number;
  resource_request_id: number;
  resource_id: number;
}
export interface ResourceRequest {
  id?: number | string;
  uuid?: string;
  notes?: string;
  // Todo: Need to define this after API discussion
  role?: Role;
  trade?: Trade;
  trade_id?: string | number;
  role_id?: string | number;
  start_date?: Date | string;
  end_date?: Date | string;
  resource_count?: number;
  status?: ResourceRequestStatus;
  project_id?: number | string;
  project?: any;
  assignments?: ResourceRequestAssignment[];
}

export interface ResourceRequestTask extends Task {
  text?: string;
  uuid?: string;
  parent?: number | string;
  progress?: number;
  type?: string;
  open?: boolean;
  readonly $id?: string;
  color?: string;
  [key: string]: any;
}

export interface ResourceTask extends Task {
  max_capacity: number;
  trades: TradeRole[];
}
