import React, { useMemo, useRef, useState } from 'react';
import {
  Chart as ChartJS,
  BarElement,
  LineElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  PointElement,
  Filler,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import {
  useGetResourceDemandGraphDataQuery,
  useGetResourceRequestGraphDataQuery,
} from '../api/analytics';
import { addMonths, differenceInDays, endOfMonth, format, max, startOfMonth } from 'date-fns';
import {
  Box,
  Stack,
  Select,
  MenuItem,
  FormControl,
  CircularProgress,
  Grid,
  LinearProgress,
} from '@mui/material';
import DateRangeSelector from './DateRangeSelector';
import { TradesAutocomplete } from 'features/trades/components/TradesAutocomplete';
import { filter, times } from 'lodash';
import { ProjectAutocomplete } from 'features/projects/components/ProjectAutocomplete';
import { useParams } from 'react-router-dom';
import { useGetAllResourcesQuery } from 'features/resources/api/resources.api';
import { parseDate } from '@blackhyve/utilities/dates';
import { useGetProjectQuery } from 'features/projects/store/project.api';

// Register Chart.js components
ChartJS.register(
  BarElement,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  Filler
);

const PERIOD_OPTIONS = [
  { name: 'Day', value: 'day' },
  { name: 'Week', value: 'week' },
  { name: 'Month', value: 'month' },
  // { name: 'Year', value: 'year' },
];


export const defaultState = {
  start_date: addMonths(startOfMonth(new Date()), -1),
  end_date: addMonths(endOfMonth(new Date()), 12),
  project_id: undefined,
  period: 'day',
  trade_id: undefined
};


export const ResourceRequestGraph = () => {
  const { projectId } = useParams();
  const chartRef = useRef(null);

  const { data: projectDetails } = useGetProjectQuery(projectId, { skip: !projectId });

  const getInitialState = () => {
    if (projectId && projectDetails) {
      return {
        ...defaultState,
        start_date: parseDate(projectDetails.forecasted_start || projectDetails.start_date),
        end_date: max([
          parseDate(projectDetails.sub_completion_date),
          parseDate(projectDetails.est_completion_date)
        ]),
        project_id: projectId
      };
    }

    return defaultState;
  };

  const [filters, setFilters] = useState(getInitialState);

  const {
    data: resourceRequestData = { assigned: [], requested: [] },
    isLoading: isLoadingResourceRequestQuery,
    isFetching: isFetchingResourceRequestQuery,
    isError,
  } = useGetResourceRequestGraphDataQuery({
    ...filters,
    start_date: format(filters.start_date, 'yyyy-MM-dd'),
    end_date: format(filters.end_date, 'yyyy-MM-dd'),
  });
  const {
    data: resourceDemandData = { active: [], bid: [] },
    isLoading: isLoadingResourceDemand,
    isFetching: isFetchingResourceDemandData,
  } = useGetResourceDemandGraphDataQuery({
    ...filters,
    start_date: format(filters.start_date, 'yyyy-MM-dd'),
    end_date: format(filters.end_date, 'yyyy-MM-dd'),
  });
  const {
    data: resources = [],
    isLoading: isLoadingActiveResourceCount,
    isFetching: isFetchingActiveResourceCount,
  } = useGetAllResourcesQuery({ type: 'labor', trade: filters.trade_id });
  const today = format(new Date(), 'yyyy-MM-dd');
  const diffDays = differenceInDays(filters.end_date, filters.start_date) + 1;

  const labels = useMemo(() => {
    const allPeriods = [
      ...resourceRequestData.assigned.map((item) => item.period),
      ...resourceRequestData.requested.map((item) => item.period),
      ...resourceDemandData.active.map((item) => item.period),
      ...resourceDemandData.bid.map((item) => item.period),
    ];
    return [...new Set(allPeriods)].sort();
  }, [
    resourceRequestData.assigned,
    resourceRequestData.requested,
    resourceDemandData.active,
    resourceDemandData.bid,
  ]);

  const chartData = useMemo(() => {
    const mapDataToDataset = (sourceData) =>
      labels.map((label) => {
        const item = sourceData.find((data) => data.period === label);
        return item ? item.count : 0;
      });

    return {
      labels,
      datasets: [
        {
          type: 'bar',
          label: 'Requested crew',
          data: mapDataToDataset(resourceRequestData.requested),
          backgroundColor: '#44a1ef', // Light Blue
          stack: 'Stack 0',
          order: 2,
        },
        {
          type: 'bar',
          label: 'Assigned crew',
          data: mapDataToDataset(resourceRequestData.assigned),
          backgroundColor: '#08325e', // Dark Blue
          stack: 'Stack 0',
          order: 1,
        },
        {
          type: 'line',
          label: 'Bid demand forecast',
          data: mapDataToDataset(resourceDemandData.bid),
          backgroundColor: 'rgba(231, 178, 119, 0.3)',
          borderColor: 'rgba(231, 178, 119)', // Yellow         
          fill: true,
          order: 2,
          pointRadius: 0,
        },
        {
          type: 'line',
          label: 'Active demand forecast',
          data: mapDataToDataset(resourceDemandData.active),
          backgroundColor: 'rgba(17, 84, 156, 0.3)',
          borderColor: 'rgba(17, 84, 156)', // Blue
          fill: true,
          order: 2,
          pointRadius: 0,
        },
        {
          type: 'line',
          label: `Available crew`,
          data: times(diffDays, () => resources.length),
          backgroundColor: '#b45857',
          borderColor: '#b45857',
          fill: false,
          borderDash: [5, 5],
          order: 0,
          pointRadius: 0,
        },
      ],
    };
  }, [
    labels,
    resourceRequestData.assigned,
    resourceRequestData.requested,
    resourceDemandData.active,
    resourceDemandData.bid,
    diffDays,
    resources,
  ]);

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
      tooltip: {
        enabled: true
      },
      title: {
        display: true,
        text: 'Resource request graph',
      },
      annotation: labels?.includes(today)
        ? {
          annotations: {
            todayLine: {
              type: 'line',
              xMin: today,
              xMax: today,
              borderColor: '#b45857',
              borderWidth: 2,
            },
          },
        }
        : {},
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        stacked: true,
        title: {
          display: true,
          text: '',
        },
        ticks: {
          callback: function (value, index, values) {
            const date = parseDate(this.getLabelForValue(value));
            return date.toLocaleDateString();
          },
        },
      },
      y: {
        grid: {
          display: false,
        },
        stacked: false,
        title: {
          display: true,
          text: 'CREW',
          font: {
            weight: 'bold',
            size: 16,
            color: 'black',
          },
        },
      },
    },
  };

  const handleDateRangeChange = (start, end) => {
    const initialState = getInitialState()
    setFilters({ ...filters, start_date: start ? start : initialState.start_date, end_date: end ? end : initialState.end_date });
  };

  const isLoading =
    isLoadingResourceRequestQuery || isLoadingResourceDemand || isLoadingActiveResourceCount;
  const isFetching =
    isFetchingResourceRequestQuery || isFetchingResourceDemandData || isFetchingActiveResourceCount;

  return (
    <Box sx={{ p: 1 }}>
      <Stack direction={'row'} gap={2}>
        {!projectId && (
          <Box sx={{ width: 300 }}>
            <ProjectAutocomplete
              multiple={false}
              value={filter.project_id}
              onChange={(_event, newValue) => {
                setFilters({ ...filters, project_id: newValue?.id || undefined });
              }}
            />
          </Box>
        )}
        <Box sx={{ width: 300 }}>
          <TradesAutocomplete
            multiple={false}
            value={filter.trade_id}
            onChange={(_event, newValue) => {
              setFilters({ ...filters, trade_id: newValue ? newValue : undefined });
            }}
          />
        </Box>
        <Box sx={{ width: 100 }}>
          <FormControl fullWidth name="period" size="small">
            <Select
              name="period"
              value={filters.period}
              variant="outlined"
              onChange={(event) => setFilters({ ...filters, period: event.target.value })}
            >
              {PERIOD_OPTIONS?.map((period) => {
                return <MenuItem value={period.value}>{period.name}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Box>
        <DateRangeSelector
          endDate={filters.end_date}
          startDate={filters.start_date}
          onDateRangeChange={handleDateRangeChange}
        />
      </Stack>
      <Stack sx={{ mt: 2 }}>
        {isFetching && <LinearProgress />}
        {isLoading ? (
          <CircularProgress className="loading" />
        ) : (
          <Grid container justifyContent="center">
            <Grid item sm={8} xs={12}>
              <div style={{ height: '600px' }}>
                <Bar
                  data={chartData}
                  options={{ ...chartOptions, maintainAspectRatio: false }}
                  ref={chartRef}
                />
              </div>
            </Grid>
          </Grid>
        )}
      </Stack>
    </Box>
  );
};
