export function transformToTable<T extends { row_index: number; column_index: number }>(
  cells: T[]
): T[][] {
  if (!cells.length) return [];

  const maxRow = Math.max(...cells.map((c) => c.row_index));
  const maxCol = Math.max(...cells.map((c) => c.column_index));

  const table: T[][] = Array(maxRow + 1)
    .fill(undefined)
    .map(() => Array(maxCol + 1).fill(undefined));

  cells.forEach((cell) => {
    table[cell.row_index][cell.column_index] = cell;
  });

  return table;
}

export function transformScheduleToTable<T extends { row: number; column: number }>(
  cells: T[]
): T[][] {
  if (!cells.length) return [];

  const maxRow = Math.max(...cells.map((c) => c.row));
  const maxCol = Math.max(...cells.map((c) => c.column));

  const table: T[][] = Array(maxRow + 1)
    .fill(undefined)
    .map(() => Array(maxCol + 1).fill(undefined));

  cells.forEach((cell) => {
    table[cell.row][cell.column] = cell;
  });

  table.shift();

  return table;
}
