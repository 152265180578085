import { parseDate } from '@blackhyve/utilities/dates';
import { transformScheduleToTable } from '../utils/transformToTable';
import _ from 'lodash';
import { parse } from 'date-fns';
import { detectIndentLevels } from '../utils/inferWbsFromBoundingBoxes';

const taskPropertyMapping = {
  Identifier: 'identifier',
  Name: 'name',
  'Scheduled Start': 'scheduled_start',
  'Scheduled End': 'scheduled_end',
  Duration: 'duration',
};

export function useScheduleEntries<T>(importData): T[] {
  if (_.isNil(importData)) return [];

  const scheduleColumns = importData?.schedule_columns || [];
  const scheduleEntries = transformScheduleToTable<{
    text: string;
    row: number;
    column: number;
  }>(importData?.schedule_entries || []);
  const mapToByIndex = scheduleColumns.reduce((acc, col) => {
    if (col.map_to) {
      acc[col.index] = col.map_to;
    }
    return acc;
  }, {});

  const indentLevels = detectIndentLevels(
    importData.schedule_entries.filter((e) => e.column === 0)
  );

  const mapped = scheduleEntries.map((row, index) => {
    const { indent_level, anomaly_type } = indentLevels[index];
    return row.reduce(
      (obj, entry) => {
        const mapTo = mapToByIndex[entry.column];
        if (mapTo) {
          const taskProperty = taskPropertyMapping[mapTo];
          switch (taskProperty) {
            // TODO: drop duration on the ground for now
            // case 'duration':
            //   obj[taskProperty] = parseFloat(entry.text);
            //   break;
            case 'scheduled_start':
            case 'scheduled_end': {
              // TODO: better date parsing
              obj[taskProperty] = parse(entry.text, 'dd-MMM-yy', new Date());
              break;
            }
            case 'name':
            case 'identifier': {
              obj[taskProperty] = entry.text;
              break;
            }
          }
        }

        if (!obj['name']) {
          obj['name'] = obj['identifier'];
          delete obj['identifier'];
        }

        if (!obj['scheduled_start'] && obj['scheduled_end']) {
          obj['scheduled_start'] = obj['scheduled_end'];
        }

        if (!obj['scheduled_end'] && obj['scheduled_start']) {
          obj['scheduled_end'] = obj['scheduled_start'];
        }

        return {
          id: 1 + index,
          ...obj,
        };
      },
      {
        indent_level,
        anomaly_type,
      } as T
    );
  });

  return mapped;
}
