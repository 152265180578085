import {
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  OutlinedInput,
  Button,
  Chip,
  Typography,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { ResourceType, ResourceUnit } from '../api/resources.models';
import { capitalize } from 'lodash';
import { useGetAllResourceGroupsQuery } from '../api/resources.api';
import { useEffect, useState } from 'react';
import { WorkDaysComponent } from 'components/common/v3/WorkDaysComponent';
import { TradesAutocomplete } from 'features/trades/components/TradesAutocomplete';
import { RolesAutocomplete } from 'features/roles/components/RolesAutocomplete';
import { useGetTradesQuery } from 'features/trades/api/trades.api';
import { useGetRolesQuery } from 'features/roles/api/role.api';
import { useSnackbar } from 'notistack';
import { TradeRole } from '../api/resources.models';

export function ResourceForm({
  form,
  isGroup,
  onSubmit,
}: {
  form: any;
  isGroup: boolean;
  onSubmit: any;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, control, setValue, watch, reset } = form;
  const { data: groupResources = [] } = useGetAllResourceGroupsQuery({});

  const { data: { entities: tradeEntities = {} } = {} } = useGetTradesQuery(undefined);
  const { data: { entities: roleEntities = {} } = {} } = useGetRolesQuery(undefined);

  const selectedTradeId = watch('trade_id');
  const selectedRoleId = watch('role_id');
  const currentTradeRoles = watch('trades') || [];

  useEffect(() => {
    if (isGroup) {
      setValue('type', ResourceType.Group);
    }
  }, [isGroup, setValue]);

  const handleAddTradeRole = () => {
    if (selectedTradeId) {
      const selectedTrade = tradeEntities[selectedTradeId];
      const selectedRole = selectedRoleId ? roleEntities[selectedRoleId] : {};

      const isDuplicate = currentTradeRoles.some(
        (tr: TradeRole) => tr.trade_id === selectedTradeId && tr?.role_id === selectedRoleId
      );

      if (isDuplicate) {
        enqueueSnackbar('You have already selected this trade', { variant: 'warning' });
        return;
      }

      const newTradeRole: TradeRole = {
        trade_id: selectedTradeId,
        trade_name: selectedTrade?.name,
        role_name: selectedRole?.name || '',
        role_id: selectedRoleId,
      };

      setValue('trades', [...currentTradeRoles, newTradeRole]);

      reset({
        ...watch(),
        trade_id: null,
        role_id: null,
      });
    }
  };

  const handleRemoveTradeRole = (index: number) => {
    const updatedTradeRoles = currentTradeRoles.filter((_, i) => i !== index);
    setValue('trades', updatedTradeRoles);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={1}>
        <Grid item xs={9}>
          <InputLabel htmlFor="name">Name*</InputLabel>
          <Controller
            control={control}
            name="name"
            rules={{ required: 'Name is required' }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                autoFocus
                fullWidth
                error={!!error}
                helperText={error?.message}
                placeholder="Resource Name"
                size="small"
                variant="outlined"
              />
            )}
          />
        </Grid>

        {!isGroup && (
          <Grid item xs={3}>
            <InputLabel htmlFor="type">Type</InputLabel>
            <Controller
              control={control}
              name="type"
              render={({ field }) => (
                <Select {...field} displayEmpty fullWidth size="small" variant="outlined">
                  <MenuItem value={ResourceType.Labor}>{capitalize(ResourceType.Labor)}</MenuItem>
                  <MenuItem value={ResourceType.Equipment}>
                    {capitalize(ResourceType.Equipment)}
                  </MenuItem>
                </Select>
              )}
            />
          </Grid>
        )}

        <Grid item xs={6}>
          <InputLabel htmlFor="unit">Unit</InputLabel>
          <Controller
            control={control}
            name="unit"
            render={({ field }) => (
              <Select {...field} displayEmpty fullWidth size="small" variant="outlined">
                <MenuItem value={ResourceUnit.Hours}>{capitalize(ResourceUnit.Hours)}</MenuItem>
              </Select>
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <InputLabel htmlFor="max_capacity">Daily Capacity</InputLabel>
          <Controller
            control={control}
            name="max_capacity"
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                placeholder="Daily Capacity"
                size="small"
                variant="outlined"
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <InputLabel>Select Working Days</InputLabel>
          <Grid container spacing={1}>
            <Controller
              control={control}
              name="workday_calendar"
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <WorkDaysComponent disabled={false} workdayCalendar={value} onChange={onChange} />
              )}
            />
          </Grid>
        </Grid>
        {!isGroup && (
          <Grid item xs={12}>
            <InputLabel>This Resource Belongs To</InputLabel>
            <Controller
              control={control}
              name="parents"
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  displayEmpty
                  fullWidth
                  error={!!error}
                  input={<OutlinedInput margin="dense" />}
                  size="medium"
                >
                  <MenuItem value={''}>None</MenuItem>
                  {groupResources?.map((resource) => (
                    <MenuItem key={resource.id} value={resource.id}>
                      {resource.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <InputLabel>Trade and Role:</InputLabel>
        </Grid>
        <Grid container item justifyContent={'center'} spacing={1} xs={12} sx={{ pt: 0 }}>
          <Grid container item spacing={1} xs={10}>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="trade_id"
                render={({ field, fieldState: { error } }) => (
                  <TradesAutocomplete
                    {...field}
                    fullWidth={true}
                    multiple={false}
                    TextFieldProps={{
                      error: !!error,
                      helperText: error?.message,
                    }}
                    onChange={(_event, newValue) => {
                      field.onChange(newValue ?? null);
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="role_id"
                render={({ field, fieldState: { error } }) => (
                  <RolesAutocomplete
                    {...field}
                    fullWidth={true}
                    multiple={false}
                    onChange={(_event, newValue) => {
                      field.onChange(newValue ?? null);
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            sm={2}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}
          >
            <Button disabled={!selectedTradeId} variant="outlined" onClick={handleAddTradeRole}>
              + Add
            </Button>
          </Grid>

          <Grid container item gap={1} xs={12}>
            {form?.formState?.errors?.root?.trades && (
              <Typography color={'error'}>
                {form?.formState?.errors?.root?.trades?.message}
              </Typography>
            )}
            {currentTradeRoles?.map((tradeRole, index) => {
              return (
                <Chip
                  key={`${tradeRole.trade_id}-${tradeRole.role_id}`}
                  label={`${tradeRole.trade_name} ${tradeRole.role_name ? `- ${tradeRole.role_name}` : ''}`}
                  onDelete={() => handleRemoveTradeRole(index)}
                />
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}
