import { Autocomplete, Checkbox, TextField } from '@mui/material';
import { forwardRef } from 'react';
import { useGetProjectQuery, useGetProjectsQuery } from '../store/project.api';
import { useSelector } from 'react-redux';
import { selectCurrentUserWorkspaceId } from 'features/auth';

export const ProjectAutocomplete = forwardRef(
  ({ TextFieldProps = undefined, multiple = false, ...props }, ref) => {

    const workspaceId = useSelector((state) => selectCurrentUserWorkspaceId(state));
    const {
      data: projects = [],
      isLoading: isLoadingTrades,
    } = useGetProjectsQuery({ workspaceId: Number(workspaceId) });

    return (
      <Autocomplete
        autoHighlight
        disableCloseOnSelect
        getOptionLabel={(option) => option?.name || 'Unknown'}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        limitTags={multiple ? 3 : -1}
        loading={isLoadingTrades}
        multiple={multiple}
        options={projects}
        ref={ref}
        size="small"
        renderInput={(params) => (
          <TextField
            {...params}
            id="project-input"
            placeholder="Select Project"
            variant="outlined"
            {...TextFieldProps}
          />
        )}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            {multiple && <Checkbox checked={selected} edge="start" />}
            {option?.name}
          </li>
        )}
        value={
          multiple
            ? projects.filter(project => props.value?.includes(project.id))
            : projects.find(project => project.id === props.value) || null
        }
        onChange={(event, newValue) => {
          // For multiple selection, return array of ids
          if (multiple) {
            const ids = newValue.map(item => item.id);
            props.onChange(event, ids);
          }
          // For single selection, return single id
          else {
            const id = newValue ? newValue.id : null;
            props.onChange(event, id);
          }
        }}
        {...props}
      />
    );
  }
);
