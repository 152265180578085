import { Table } from 'components/table/Table';
import { ResourceMoreOptions } from './ResourceMoreOptions';
import { capitalize } from 'lodash';
import { Resource } from '../api/resources.models';
import { concatenateParentAndChildNames } from '../helpers/resourceHelper';
import { WorkDaysComponent } from 'components/common/v3/WorkDaysComponent';

export const ResourcesTable = ({
  resources,
  parentResourceMap,
  TableProps,
}: {
  resources: Resource[];
  parentResourceMap: Record<number, string>;
  TableProps: any;
}) => {
  const columns = [
    {
      field: 'name',
      label: 'Name',
      sort: true,
      render: (resource) => {
        const displayName = concatenateParentAndChildNames({ resource, parentResourceMap });
        const capitalizeEachWord = (str) => str.replace(/\b\w/g, (char) => char.toUpperCase());
        return capitalizeEachWord(displayName);
      },
    },
    {
      field: 'type',
      label: 'Type',
      sort: true,
      render: (resource) => capitalize(resource.type),
    },
    {
      field: 'max_capacity',
      label: 'Max Daily Capacity',
      sort: true,
      render: (resource) => {
        return (
          <>
            {resource.max_capacity} {capitalize(resource.unit)}
          </>
        );
      },
    },
    {
      field: 'trades',
      label: 'Trades',
      sort: false,
      render: (resource) => {
        return '';
      },
    },
    {
      field: 'workday_calendar',
      label: 'Workday Calendar',
      sort: true,
      render: (resource) => {
        return (
          <WorkDaysComponent
            disabled={true}
            workdayCalendar={resource?.workday_calendar}
            onChange={() => {}}
          />
        );
      },
    },
    {
      field: 'moreOptions',
      cellProps: { align: 'right' },
      render: (resource) => (
        <div onClick={(event) => event.stopPropagation()}>
          <ResourceMoreOptions isGroup={false} resource={resource} />
        </div>
      ),
    },
  ];

  return <Table columns={columns} rows={resources} {...TableProps} />;
};

export default ResourcesTable;
