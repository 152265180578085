import { AdditionalTaskLayer, GanttConfigOptions, GanttStatic } from '@blackhyve/dhtmlx-gantt';
import { getResourceConfig } from './resourceConfig';

const renderResourceTaskBar: AdditionalTaskLayer['TaskLayerRender'] = function (
  this: GanttStatic,
  task,
  timeline,
  config
) {
  const gantt = this;
  if (task.$role === 'task') {
    let end_date = task.start_date && gantt.calculateEndDate(task.start_date, task.duration);
    const sizes = timeline.getItemPosition(task, task.start_date, end_date);

    const row = document.createElement('div');
    row.classList.add('gantt_task_line');
    row.style.cssText = [
      `left: ${sizes?.left}px`,
      `width: ${sizes?.width}px`,
      `position:absolute`,
      `height: ${(config?.row_height || 20) - 5}px`,
      `line-height: ${(config?.row_height || 20) - 5}px`,
      `top: ${sizes?.top + 2}px`,
      `color: #fff`,
      `text-align:center`,
      `font-size: 12px`,
    ].join(';');

    return row;
  }
};

// adding layer function that describe the way of styling data
const resourceLayers = ['resourceRow', renderResourceTaskBar, 'taskBg'];

export const getLayout = function (gantt: GanttStatic): GanttConfigOptions['layout'] {
  return {
    css: 'gantt_container',
    rows: [
      {
        gravity: 2,
        cols: [
          {
            width: 600,
            group: 'grids',
            css: 'gantt_task_grid',
            rows: [
              {
                view: 'grid',
                scrollX: 'gridScrollX',
                scrollY: 'mainScrollY',
                // scrollable: true,
              },
              { view: 'scrollbar', id: 'gridScrollX' },
            ],
          },
          { resizer: true, width: 1, group: 'resizer' },
          {
            rows: [{ view: 'timeline', scrollY: 'mainScrollY', scrollX: 'mainScrollX' }],
          },
          { view: 'scrollbar', id: 'mainScrollY' },
        ],
      },
      { resizer: true, width: 1 },
      {
        config: getResourceConfig(gantt),
        cols: [
          {
            width: 600,
            group: 'grids',
            rows: [
              {
                view: 'resourceGrid',
                scrollX: 'resourceGridScrollX',
                scrollY: 'resourceScrollY',
              },
              { view: 'scrollbar', id: 'resourceGridScrollX' },
            ],
          },
          { resizer: true, width: 1, group: 'resizer' },
          {
            view: 'resourceTimeline',
            scrollX: 'mainScrollX',
            scrollY: 'resourceScrollY',
            layers: resourceLayers,
          },
          { view: 'scrollbar', id: 'resourceScrollY', group: 'vertical' },
        ],
        gravity: 1,
      },
      { view: 'scrollbar', id: 'mainScrollX' },
    ],
  };
};
