import { NewTask, Task } from '@blackhyve/dhtmlx-gantt';
import { parseDate } from '@blackhyve/utilities/dates';
import { addDays, format } from 'date-fns';
import {
  NewResourceProjectAssignment,
  Resource,
  ResourceRequestAssignment,
  ResourceRequestAssignmentTask,
  ResourceRequest,
  ResourceRequestStatus,
  ResourceTask,
} from 'features/resources/api/resources.models';

export function formatResourceRequest(resourceRequest: ResourceRequest): NewTask[] {
  return [
    {
      id: Number(resourceRequest?.id),
      uuid: generateResourceRequestUUID(resourceRequest?.id),
      text: `${resourceRequest?.trade?.name}${resourceRequest?.role?.name ? ` - ${resourceRequest?.role?.name}` : ''}`,
      start_date: parseDate(resourceRequest?.start_date),
      end_date: addDays(parseDate(resourceRequest?.end_date), 1),
      type: 'resource_request',
      parent:
        resourceRequest.status === ResourceRequestStatus.Open ? 'open-requests' : 'closed-requests',
      resource_count: resourceRequest?.resource_count,
      status: resourceRequest?.status,
      trade_id: resourceRequest.trade_id,
      role_id: resourceRequest.role_id,
      project_id: resourceRequest?.project_id,
      $open: false,
    },
    ...(resourceRequest?.assignments?.map((assignment) =>
      formatResourceRequestAssignment(assignment, { project_id: resourceRequest?.project_id })
    ) ?? []),
  ];
}

export function undoFormatResourceRequest(formattedTask: Task): ResourceRequest {
  return {
    id: Number(formattedTask?.id),
    status: formattedTask?.status,
    start_date: formattedTask?.start_date,
    end_date: formattedTask?.end_date && addDays(formattedTask?.end_date, -1),
  };
}

export function formatResourceRequestAssignment(
  assignment: ResourceRequestAssignment,
  extras = {}
): ResourceRequestAssignmentTask {
  return {
    id: `${assignment.resource_request_id}_${assignment.id}`,
    import_id:
      assignment?.import_id && `${assignment.resource_request_id}_${assignment?.import_id}`,
    start_date: parseDate(assignment.start_date),
    end_date: addDays(parseDate(assignment.end_date), 1),
    parent: assignment?.resource_request_id,
    type: 'task',
    resource_id: assignment.resource_id,
    resource_request_id: assignment?.resource_request_id,
    ...extras,
  };
}

export function undoFormatResourceRequestAssignment(
  formattedTask: ResourceRequestAssignmentTask
): ResourceRequestAssignment {
  let assignmentId = '';
  if (formattedTask?.id) {
    assignmentId = formattedTask.id.toString().split('_')[1];
  }
  return {
    id: Number(assignmentId),
    start_date: format(formattedTask.start_date || new Date(), 'yyyy-MM-dd'),
    end_date: format(
      formattedTask.end_date
        ? addDays(formattedTask.end_date, -1)
        : formattedTask.start_date
          ? formattedTask.start_date
          : new Date(),
      'yyyy-MM-dd'
    ),
    resource_id: Number(formattedTask.resource_id),
    resource_request_id: Number(formattedTask.resource_request_id),
  };
}

export function formatResource(resource: Resource): ResourceTask {
  return {
    id: Number(resource.id),
    text: resource.name,
    max_capacity: resource.max_capacity || 0,
    trades: resource.trades || [],
    $open: false,
  };
}

export function undoFormatResource(resourceTask: ResourceTask): Partial<Resource> {
  return {
    id: Number(resourceTask.id),
    name: resourceTask.text,
    max_capacity: resourceTask.max_capacity || 0,
  };
}

export const generateResourceRequestUUID = (id) => {
  const minPadding = 6;
  const numDigits = String(id).length;
  const padding = Math.max(minPadding, numDigits);
  return `RR-${String(id).padStart(padding, '0')}`;
};
