import { useEffect } from 'react';

export const useGanttLoadTaskAndLinks = ({
  gantt,
  tasks,
  links,
  resources,
  assignments,
  options = {},
}) => {
  const { skip = false } = options;

  useEffect(() => {
    if (
      gantt &&
      !gantt.$destroyed &&
      (!gantt.hasPendingUpdates || !gantt.hasPendingUpdates()) &&
      !skip
    ) {
      const scrollState = gantt.getScrollState();
      gantt.clearAll();
      gantt.parse({
        tasks,
        links,
        resources,
        assignments,
      });
      gantt.scrollTo(scrollState.x, scrollState.y);
    }
  }, [assignments, gantt, links, resources, skip, tasks]);
};
