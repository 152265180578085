import _ from 'lodash';

interface TextBlock {
  id: number;
  text: string;
  bounding_box_left: string;
}

interface ProcessedBlock extends TextBlock {
  indent_level: number;
  confidence: number;
  anomaly_type: 'too_deep' | 'should_be_parent' | null;
}

interface ClusterStats {
  mean: number;
  std: number;
  count: number;
}

const calculateStats = (points: number[]): { mean: number; std: number } => {
  let mean = 0;
  let m2 = 0;
  let count = 0;

  points.forEach((x) => {
    count++;
    const delta = x - mean;
    mean += delta / count;
    const delta2 = x - mean;
    m2 += delta * delta2;
  });

  if (count < 2) {
    return { mean: points[0] || 0, std: 0.0001 };
  }

  const std = Math.max(Math.sqrt(m2 / (count - 1)), 0.0001);
  return { mean, std };
};

const findIndentClusters = (
  xPositions: number[],
  bandwidthMultiplier = 0.1
): Map<number, ClusterStats> => {
  const sorted = [...xPositions].sort((a, b) => a - b);
  const spread = sorted[sorted.length - 1] - sorted[0];
  const bandwidth = spread * bandwidthMultiplier;

  const clusters = new Map<number, number[]>();
  let currentCluster: number[] = [sorted[0]];
  let clusterCenter = sorted[0];

  for (let i = 1; i < sorted.length; i++) {
    const x = sorted[i];
    if (x - clusterCenter <= bandwidth) {
      currentCluster.push(x);
    } else {
      clusters.set(clusterCenter, currentCluster);
      currentCluster = [x];
      clusterCenter = x;
    }
  }

  if (currentCluster.length > 0) {
    clusters.set(clusterCenter, currentCluster);
  }

  return new Map(
    Array.from(clusters.entries()).map(([center, points]) => [
      center,
      {
        ...calculateStats(points),
        count: points.length,
      },
    ])
  );
};

const calculateConfidence = (x: number, clusterStats: ClusterStats, maxStdDevs = 3): number => {
  const zScore = Math.abs((x - clusterStats.mean) / clusterStats.std);
  return Math.max(0, 1 - zScore / maxStdDevs);
};

interface ValidationResult {
  valid: boolean;
  errors: Array<{
    index: number;
    message: string;
    type: 'orphaned' | 'too_deep' | 'invalid_parent';
  }>;
}

interface SyntheticParent {
  id: number;
  text: string;
  bounding_box_left: string;
  indent_level: number;
  confidence: number;
  anomaly_type: null;
  synthetic: true;
}

const generateSyntheticParents = (
  blocks: ProcessedBlock[]
): (ProcessedBlock | SyntheticParent)[] => {
  // find the first real task's level
  if (blocks.length === 0) return blocks;

  const firstLevel = blocks[0].indent_level;
  if (firstLevel === 0) return blocks;

  // omg we need parents! quick, to the parent generator!
  const syntheticParents: SyntheticParent[] = Array.from({ length: firstLevel }, (_, i) => ({
    id: -1 - i, // negative ids for our synthetic friends
    text: `[synthetic parent level ${i}]`,
    bounding_box_left: '0', // they're all flush left because they're responsible adults
    indent_level: i,
    confidence: 1, // they're very confident in their parenting abilities
    anomaly_type: null,
    synthetic: true,
  }));

  return [...syntheticParents, ...blocks];
};

const validateNesting = (blocks: ProcessedBlock[]): ValidationResult => {
  const errors: ValidationResult['errors'] = [];
  const parentStack: Array<{ level: number; index: number }> = [];

  // adopt some parents if needed
  const enrichedBlocks = generateSyntheticParents(blocks);

  enrichedBlocks.forEach((block, index) => {
    const currentLevel = block.indent_level;

    // pop parents that are deeper than current level
    while (parentStack.length > 0 && parentStack[parentStack.length - 1].level >= currentLevel) {
      parentStack.pop();
    }

    // check if we have a valid parent
    if (currentLevel > 0) {
      if (parentStack.length === 0) {
        errors.push({
          index,
          message: `task at level ${currentLevel} has no parent`,
          type: 'orphaned',
        });
      } else {
        const parent = parentStack[parentStack.length - 1];
        if (currentLevel > parent.level + 1) {
          errors.push({
            index,
            message: `task jumps from level ${parent.level} to ${currentLevel}`,
            type: 'too_deep',
          });
        }
      }
    }

    // add current task as potential parent
    parentStack.push({ level: currentLevel, index });
  });

  return {
    valid: errors.length === 0,
    errors,
  };
};

const detectNestingAnomalies = (blocks: ProcessedBlock[]): ProcessedBlock[] => {
  return blocks.map((block, index) => {
    if (index === 0) return block;

    const currentLevel = block.indent_level;
    const parentLevel = blocks[index - 1].indent_level;

    // Look ahead to find next sibling or parent level
    let nextSiblingIndex = index + 1;
    while (
      nextSiblingIndex < blocks.length &&
      blocks[nextSiblingIndex].indent_level > parentLevel
    ) {
      nextSiblingIndex++;
    }

    let anomaly_type: any = null;

    if (currentLevel > parentLevel + 1) {
      // If next tasks are at same level as current, this should be a parent
      if (
        nextSiblingIndex < blocks.length &&
        blocks[nextSiblingIndex].indent_level === currentLevel
      ) {
        anomaly_type = 'should_be_parent';
      } else {
        // Otherwise it's just too deeply nested
        anomaly_type = 'too_deep';
      }
    }

    return {
      ...block,
      anomaly_type,
    };
  });
};

export const detectIndentLevels = (
  blocks: TextBlock[],
  options = {
    bandwidthMultiplier: 0.1,
    confidenceThreshold: 0.7,
    minClusterSize: 2,
  }
): ProcessedBlock[] => {
  const xPositions = blocks.map((b) => parseFloat(b.bounding_box_left));
  const clusters = findIndentClusters(xPositions, options.bandwidthMultiplier);

  const sortedClusters = Array.from(clusters.entries())
    .filter(([, stats]) => stats.count >= options.minClusterSize)
    .sort(([a], [b]) => a - b);

  const clusterToLevel = new Map(sortedClusters.map(([center], idx) => [center, idx]));

  const processedBlocks = blocks.map((block) => {
    const x = parseFloat(block.bounding_box_left);
    const [closestCenter, stats] = _.minBy(sortedClusters, ([center]) => Math.abs(center - x)) || [
      sortedClusters[0][0],
      sortedClusters[0][1],
    ];

    const confidence = calculateConfidence(x, stats);
    const level = clusterToLevel.get(closestCenter) || 0;

    return {
      ...block,
      indent_level: level,
      confidence,
      anomaly_type: null,
    };
  });

  return generateSyntheticParents(detectNestingAnomalies(processedBlocks));
};
