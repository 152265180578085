import { ContentLayout, MainLayout } from 'components/layouts';
import { ActivityFeed } from 'features/activityFeeds/routes/index';
import { AnalyticsWindow } from 'features/analytics/routes/AnalyticsWindow';
import { selectCurrentUserWorkspaceId } from 'features/auth';
import { RequireAuth } from 'features/auth/components/RequireAuth';
import { RequireRole } from 'features/auth/components/RequireRole';
import BudgetCodesIndex from 'features/budgets/components/BudgetCodesIndex';
import { companiesRoutes } from 'features/companies/routes';
import NotificationLog from 'features/notifications/components/NotificationLog';
import { Notifications } from 'features/notifications/routes';
import { operationsRoutes } from 'features/operations/routes';
import { projectsRoutes } from 'features/projects/routes';
import { recipesRoutes } from 'features/recipes/routes';
import { resourceRoutes } from 'features/resources';
import GlobalStuckPoints from 'features/stuckPoints/components/GlobalStuckPoints';
import { tagRoutes } from 'features/tags/routes';
import { TaskPortfolio } from 'features/tasksGantt';
import { UserProfile } from 'features/users';
import { Workspace } from 'features/workspace';
import { WorkspaceSwitcherBar } from 'features/workspace/components/WorkspaceSwitcherBar';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useParams } from 'react-router-dom';

const App = () => {
  return (
    <RequireAuth>
      <Outlet />
    </RequireAuth>
  );
};

const Home = () => {
  const workspaceId = useSelector((state) => selectCurrentUserWorkspaceId(state));
  if (!workspaceId) {
    return <Navigate replace to={'/workspace'} />;
  } else {
    return <Navigate replace to={`/workspace/${workspaceId}/projects`} />;
  }
};

const WorkspaceLayout = () => {
  const workspaceId = useSelector((state) => selectCurrentUserWorkspaceId(state));
  const { workspaceId: urlWorkspaceId } = useParams();
  if (!workspaceId) {
    // return <Navigate replace to={`/workspace`} />;
    return null;
  } else if (parseInt(workspaceId) !== parseInt(urlWorkspaceId)) {
    return <Navigate replace to={`/workspace/${workspaceId}`} />;
  } else {
    return (
      <MainLayout>
        <ContentLayout header={<WorkspaceSwitcherBar />}>
          <Outlet />
        </ContentLayout>
      </MainLayout>
    );
  }
};

export const protectedRoutes = [
  {
    element: <App />,
    children: [
      { path: 'workspace', element: <Workspace /> },
      {
        path: 'workspace/:workspaceId',
        element: <WorkspaceLayout />,
        children: [
          { index: true, element: <Home /> },
          {
            path: 'projects/*',
            children: projectsRoutes,
          },
          {
            path: 'tasks/gantt',
            element: <TaskPortfolio />,
          },
          {
            path: 'companies/*',
            element: (
              <RequireRole exclude roles={['contractor_contact']}>
                <Outlet />
              </RequireRole>
            ),
            children: companiesRoutes,
          },
          {
            path: 'recipes/*',
            element: (
              <RequireRole exclude roles={['contractor_contact']}>
                <Outlet />
              </RequireRole>
            ),
            children: recipesRoutes,
          },
          {
            path: 'notifications/*',
            element: <Notifications />,
          },
          { path: 'stuck-points', element: <GlobalStuckPoints /> },
          { path: 'tags', children: tagRoutes },
          { path: 'resources', children: resourceRoutes },
          {
            path: 'activity-feeds/*',
            element: (
              <RequireRole
                exclude
                fallbackComponent={<Navigate replace to={'..'} />}
                roles={['contractor_contact']}
              >
                <ActivityFeed />
              </RequireRole>
            ),
          },
          {
            path: 'notification-log/*',
            element: (
              <RequireRole
                exclude
                fallbackComponent={<Navigate replace to={'..'} />}
                roles={['contractor_contact']}
              >
                <NotificationLog />
              </RequireRole>
            ),
          },
          { path: 'user-profile', element: <UserProfile /> },
          {
            path: 'budget-codes',
            element: (
              <RequireRole
                exclude
                fallbackComponent={<Navigate replace to={'..'} />}
                roles={['contractor_contact']}
              >
                <BudgetCodesIndex />
              </RequireRole>
            ),
          },
          {
            path: 'analytics',
            element: (
              <RequireRole
                exclude
                fallbackComponent={<Navigate replace to={'..'} />}
                roles={['contractor_contact']}
              >
                <AnalyticsWindow isGlobal={true} />
              </RequireRole>
            ),
          },
          {
            path: 'operations',
            element: (

              <Outlet />

            ),
            children: operationsRoutes,
          },
        ],
      },
      { path: '*', element: <Home /> },
      { index: true, element: <Home /> },
    ],
  },
];
