export interface Import {
  id: number;
  s3_key: string;
  type: string;
  status: string;
  created_at: string;
  updated_at: string | null;
  project_id: number;
  textract_job_id: string | null;
  name: string;
  previewUrl: string;
  schedule_columns: any;
  schedule_entries: any;
  labor_estimate_columns: LaborEstimateColumn[];
  labor_estimate_entries: LaborEstimateEntry[];
}

export interface ScheduleColumn {
  id: number;
  barry_extract_id: number;
  index: number;
  name: string;
  map_to: string | null;
  created_at: string;
  updated_at: string;
}

export interface ScheduleEntry {
  id: number;
  barry_extract_id: number;
  row: number;
  column: number;
  page: number;
  indent_level: number;
  text: string;
  bounding_box_top: number;
  bounding_box_left: number;
  bounding_box_width: number;
  bounding_box_height: number;
  confidence: number;
  created_at: string;
  updated_at: string;
}

export interface LaborEstimateColumn {
  id: number;
  barry_extract_id: number;
  row_index: number;
  column_index: number;
  name: string;
  map_to: string;
  created_at: string;
  updated_at: string;
}

export interface LaborEstimateEntry {
  id: number;
  barry_extract_id: number;
  row_index: number;
  column_index: number;
  value: string;
  created_at: string;
  updated_at: string;
}

export interface BoundingBox {
  bounding_box_top: number;
  bounding_box_left: number;
  bounding_box_width: number;
  bounding_box_height: number;
}

export interface CreatedImportResponse {
  data: Import;
  key: string;
  url: string;
}

export enum ImportType {
  Schedule = 'schedule',
  LaborEstimate = 'labor_estimate',
}

export enum ImportStatus {
  UploadProcessing = 'upload_processing',
  UploadFailed = 'upload_failed',
  UploadSucceeded = 'upload_succeeded',
  ExtractQueued = 'extract_queued',
  ExtractDownloading = 'extract_downloading',
  ExtractProcessing = 'extract_processing',
  ExtractFailed = 'extract_failed',
  ExtractSucceeded = 'extract_succeeded',
}

export enum BarryExtractType {
  Schedule = 'schedule',
  Labor = 'labor',
}

export type ByImportType<T> = {
  [k in ImportType]: T;
};
