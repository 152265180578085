import {
  Box,
  Container,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  FormControl,
  CircularProgress,
  Alert,
  Skeleton,
  Fade,
  useTheme,
} from '@mui/material';
import { ContentLayout } from 'components/layouts';
import { useGetProjectGeneratedTagsWithTasksQuery } from 'features/tags/store/tag.api';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetImportQuery,
  useCreateLaborEstimateAreasMutation,
  usePerformTemplateAreaMatchingMutation,
} from '../api/imports.api';
import { groupBy } from 'lodash';
import { AutoAwesome } from '@mui/icons-material';

interface Tag {
  id: number;
  concatenatedName: string;
}

export function MatchLaborEstimateAreas() {
  const smartTags = ['area'];
  const theme = useTheme();
  const navigate = useNavigate();
  const { projectId, importId } = useParams();
  const [matches, setMatches] = useState<Record<string, number>>({});
  const [error, setError] = useState<string | null>(null);
  const [isThinking, setIsThinking] = useState(false);
  const [createLaborEstimateAreaMatching] = usePerformTemplateAreaMatchingMutation();
  const [updateLaborEstimateAreas, { isLoading: isUpdating }] =
    useCreateLaborEstimateAreasMutation();

  const { data: tagHierarchy = [], isLoading: isLoadingTags } =
    useGetProjectGeneratedTagsWithTasksQuery({ projectId, smartTags });

  const { data: importData, isLoading: isLoadingImport } = useGetImportQuery({
    backendImportId: importId,
  });

  const laborEstimateAreas = groupBy(
    (importData?.labor_estimate_entries || []).filter((e) => e.column_index === 0),
    'value'
  );

  const handleMatchChange = (laborArea: string, tagId: number) => {
    setMatches((prev) => ({
      ...prev,
      [laborArea]: tagId,
    }));
    setError(null);
  };

  const handleNext = async () => {
    try {
      const laborEstimateAreas = Object.entries(matches).map(([area_name, tag_id]) => ({
        area_name,
        tag_id: tag_id || null,
      }));

      await updateLaborEstimateAreas({
        backendImportId: parseInt(importId ?? '', 10),
        createData: {
          areas: laborEstimateAreas,
        },
      });

      navigate('../schedule', { relative: 'path' });
    } catch (err) {
      setError('Failed to update matches. Please try again.');
      console.error('Failed to update matches:', err);
    }
  };

  const handleBarryClick = async () => {
    if (!importData?.labor_estimate_entries) return;
    setIsThinking(true);

    try {
      const laborEstimateAreaNames = Object.keys(laborEstimateAreas);
      const blackhyveAreas = tagHierarchy.map((tag: Tag) => 
        JSON.stringify({ name: tag.concatenatedName, id: tag.id })
      );

      const result = await createLaborEstimateAreaMatching({
        backendImportId: parseInt(importId ?? '', 10),
        matchingData: {
          source_areas: laborEstimateAreaNames,
          target_areas: blackhyveAreas,
        },
      }).unwrap();

      if (result?.matches) {
        setMatches(
          result.matches.reduce((acc, match) => ({
            ...acc,
            ...(match.target ? { [match.source]: match.target.id } : {}),
          }), {})
        );
      }

      setError(null);
    } catch (err) {
      console.error('Failed to perform area matching:', err);
      setError('Failed to perform automatic area matching. Please try manual matching.');
    } finally {
      setIsThinking(false);
    }
  };

  if (isLoadingTags || isLoadingImport) {
    return (
      <ContentLayout>
        <Container maxWidth="xl">
          <Box sx={{ py: 4 }}>
            <Skeleton height={60} variant="text" width="40%" />
            <Box sx={{ mt: 4 }}>
              <Skeleton height={400} variant="rectangular" />
            </Box>
          </Box>
        </Container>
      </ContentLayout>
    );
  }

  const unmatchedAreas = Object.keys(laborEstimateAreas).filter((area) => !matches[area]);
  const hasUnmatchedAreas = unmatchedAreas.length > 0;

  return (
    <ContentLayout>
      <Container maxWidth="xl">
        <Box sx={{ py: 4, '& > *': { mb: 3 } }}>
          {/* Header Section */}
          <Box 
            sx={{ 
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderBottom: `2px solid ${theme.palette.primary.main}`,
              pb: 1,
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontWeight: 700,
                color: theme.palette.primary.main,
              }}
            >
              Match Labor Estimate Areas
            </Typography>
            <Button 
              disabled={isThinking} 
              startIcon={<AutoAwesome />} 
              sx={{ ml: 2 }}  // Add some margin to the left of the button
              variant="outlined"
              onClick={handleBarryClick}
            >
              {isThinking ? "Barry is Thinking..." : "March Areas with Barry"}
            </Button>
          </Box>

          {error && (
            <Fade in>
              <Alert severity="error" onClose={() => setError(null)}>
                {error}
              </Alert>
            </Fade>
          )}

          <Paper elevation={3} sx={{ p: 2 }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontWeight: 700,
                        backgroundColor: theme.palette.primary.light,
                        color: theme.palette.primary.contrastText,
                      }}
                    >
                      Labor Estimate Area
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 700,
                        backgroundColor: theme.palette.primary.light,
                        color: theme.palette.primary.contrastText,
                      }}
                    >
                      Project Area
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(laborEstimateAreas).map((area) => (
                    <TableRow
                      key={area}
                      sx={{
                        '&:nth-of-type(odd)': {
                          backgroundColor: theme.palette.action.hover,
                        },
                        '&:hover': {
                          backgroundColor: theme.palette.action.selected,
                        },
                      }}
                    >
                      <TableCell sx={{ fontWeight: 500 }}>{area}</TableCell>
                      <TableCell>
                        <FormControl fullWidth>
                          <Select
                            displayEmpty
                            sx={{ '& .MuiSelect-select': { py: 1.5 } }}
                            value={matches[area] || ''}
                            onChange={(e) => handleMatchChange(area, e.target.value as number)}
                          >
                            <MenuItem value="">
                              <em>Select an area...</em>
                            </MenuItem>
                            {tagHierarchy.map((tag: Tag) => (
                              <MenuItem key={tag.id} value={tag.id}>
                                {tag.concatenatedName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>

          {hasUnmatchedAreas && (
            <Alert severity="info" sx={{ mt: 2 }}>
              {unmatchedAreas.length} area{unmatchedAreas.length !== 1 ? 's' : ''} still need
              {unmatchedAreas.length === 1 ? 's' : ''} to be matched
            </Alert>
          )}

          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2, mt: 4 }}>
            <Button
              color="inherit"
              sx={{ px: 4 }}
              variant="outlined"
              onClick={() => navigate('../', { relative: 'path' })}
            >
              Back
            </Button>
            <Button
              color="primary"
              disabled={isUpdating}
              sx={{ px: 6, position: 'relative' }}
              variant="contained"
              onClick={handleNext}
            >
              {isUpdating ? (
                <>
                  <CircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      left: '50%',
                      marginLeft: '-12px',
                    }}
                  />
                  <span style={{ visibility: 'hidden' }}>Next</span>
                </>
              ) : (
                'Next'
              )}
            </Button>
          </Box>
        </Box>
      </Container>
    </ContentLayout>
  );
}
