import { parseDate } from '@blackhyve/utilities/dates';
import { useSubscribeDependenciesQuery } from 'features/dependencies/api/dependency.api';
import { useGantt } from 'features/gantt/hooks/useGantt';
import {
  useGetProjectHolidaysQuery,
  useGetProjectQuery,
} from 'features/projects/store/project.api';
import { useGetTagsQuery } from 'features/tags/store/tag.api';
import { useSubscribeTasksQuery } from 'features/tasks/store/task.api';
import { useTaskGanttDataProcessor } from 'features/tasksGantt/hooks/useTaskGanttDataProcessor';
import { useMemo } from 'react';
import { useGanttInlineEditorConfig } from '../../gantt/hooks/useGanttInlineEditorConfig';
import { layers } from '../config/layers';
import { templates } from '../config/templates';
import { formatTask, undoFormatTask } from '../utils/formatter';
import { useLookaheadConfig } from './useLookaheadConfig';
import { useLookaheadEvents } from './useLookaheadEvents';
import { useLookaheadMarkers } from './useLookaheadMarkers';

const emptyObject = {};
const emptyArray = [];

const plugins = {
  auto_scheduling: true,
  zoom: false,
  multiselect: false,
  marker: true,
  tooltip: false,
  inline_editors: true,
  keyboard_navigation: true,
  export_api: true,
};

const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
const today = new Date();

const formatters = { format: formatTask, undoFormat: undoFormatTask };

export const useLookaheadGantt = ({
  taskId,
  projectId,
  startDate = today,
  targetsDate,
  colorBy,
  weeks,
  displayOptions,
}) => {
  const {
    data: taskData,
    isLoading: isLoadingTasks,
    isFetching: isFetchingTasks,
  } = useSubscribeTasksQuery({ projectId, include: undefined });
  const {
    data: dependenciesData,
    isLoading: isLoadingDependencies,
    isFetching: isFetchingDependencies,
  } = useSubscribeDependenciesQuery({ projectId });
  const {
    data: project = emptyObject,
    isLoading: isLoadingProject,
    isFetching: isFetchingProject,
  } = useGetProjectQuery(projectId);

  const {
    data: projectHolidays = emptyObject,
    isLoading: isLoadingHolidays,
    isFetching: isFetchingHolidays,
  } = useGetProjectHolidaysQuery(projectId);

  const { data: tags = emptyArray, isLoading: isLoadingTags } = useGetTagsQuery({});

  const workdays = useMemo(
    () => days.map((day) => parseInt(project?.workday_calendar?.[day]) || 0),
    [project?.workday_calendar]
  );

  const holidays = useMemo(
    () => (projectHolidays?.holidays || []).map((date) => parseDate(date)),
    [projectHolidays?.holidays]
  );

  const { initDataProcessor, isSaving } = useTaskGanttDataProcessor({
    formatters: formatters,
  });

  const getConfig = useLookaheadConfig({ startDate, weeks });

  const markers = useLookaheadMarkers({ targetsDate });

  const events = useLookaheadEvents({ rootId: taskId });

  const inlineEditorConfig = useGanttInlineEditorConfig();

  const tasks = useMemo(
    () => taskData?.ids?.map((taskId) => formatters.format(taskData.entities[taskId])),
    [taskData]
  );
  const links = useMemo(
    () => dependenciesData?.ids.map((dependencyId) => dependenciesData.entities[dependencyId]),
    [dependenciesData]
  );

  const isLoading =
    isLoadingTasks || isLoadingDependencies || isLoadingHolidays || isLoadingProject;

  const lookaheadGantt = useGantt({
    tasks: tasks,
    links: links,
    zoomConfig: null,
    inlineEditorConfig: inlineEditorConfig,
    onBeforeInit: initDataProcessor,
    config: getConfig,
    plugins: plugins,
    // groupBy: groupBy,
    colorBy: colorBy,
    workdays: workdays,
    holidays: holidays,
    layers: layers,
    events: events,
    markers: markers,
    isSaving: isSaving,
    templates: templates,
    isLoading: isLoading,
    displayOptions: displayOptions,
    isLoadingHolidays: isLoadingHolidays,
  });

  return {
    isLoading: isLoadingTasks,
    isFetching: isFetchingTasks,
    ...lookaheadGantt,
  };
};
