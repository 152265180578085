import { Autocomplete, Checkbox, TextField, TextFieldProps } from '@mui/material';
import { forwardRef } from 'react';
import { AutocompleteProps } from '@mui/material/Autocomplete';
import { useGetTradesQuery } from '../api/trades.api';

interface TradesAutocompleteProps
  extends Omit<
    AutocompleteProps<string, boolean, boolean, boolean>,
    'renderInput' | 'options' | 'getOptionLabel'
  > {
  TextFieldProps?: Partial<TextFieldProps>;
  multiple?: boolean;
}

export const TradesAutocomplete = forwardRef<HTMLDivElement, TradesAutocompleteProps>(
  ({ TextFieldProps = undefined, multiple = false, ...props }, ref) => {
    const {
      data: { entities: tradeEntities = {}, ids: tradeIds = [] } = {},
      isLoading: isLoadingTrades,
    } = useGetTradesQuery({});

    return (
      <Autocomplete
        autoHighlight
        disableCloseOnSelect
        getOptionLabel={(option: string) => tradeEntities?.[option]?.name || 'Unknown'}
        limitTags={multiple ? 3 : -1}
        loading={isLoadingTrades}
        multiple={multiple}
        options={tradeIds}
        ref={ref}
        size="small"
        renderInput={(params) => (
          <TextField
            {...params}
            id="contact-trade-input"
            placeholder="Select Trade"
            variant="outlined"
            {...TextFieldProps}
          />
        )}
        renderOption={(props, option: string, { selected }) => (
          <li {...props}>
            {multiple && <Checkbox checked={selected} edge="start" />}
            {tradeEntities[option]?.name}
          </li>
        )}
        {...props}
      />
    );
  }
);
