import { Box, Typography, Card } from '@mui/material';
import { MappingEditor } from './MappingEditor';

export function TaskToLaborEstimates({
  task,
  mappings,
  items,
  onAddMapping,
  onRemoveMapping,
  onChangeMapping,
}) {
  const filteredMappings = mappings.filter((m) => m.task_id === task.id);

  return (
    <Card sx={{ mb: 1 }}>
      <Box sx={{ p: 1, borderBottom: 1, borderColor: 'divider' }}>
        <Typography fontWeight="bold" variant="subtitle2">
          {task.name}
        </Typography>
      </Box>
      <Box sx={{ p: 1 }}>
        {filteredMappings.map((m) => (
          <MappingEditor
            items={items}
            key={m.id}
            mapping={m}
            onAdd={onAddMapping}
            onChange={onChangeMapping}
            onRemove={onRemoveMapping}
          />
        ))}
        <MappingEditor
          items={items}
          onAdd={onAddMapping}
          onChange={onChangeMapping}
          onRemove={onRemoveMapping}
        />
      </Box>
    </Card>
  );
}
