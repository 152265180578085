import { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  IconButton,
  Typography,
  Paper,
  Stack,
  Tooltip,
  Button,
  CircularProgress,
  Chip,
} from '@mui/material';
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useGetImportQuery } from '../api/imports.api';
import { detectIndentLevels } from '../utils/inferWbsFromBoundingBoxes';
import { useImportTasksMutation } from 'features/tasks/store/task.api';
import { transformScheduleToTable } from '../utils/transformToTable';
import { useScheduleEntries } from '../hooks/useScheduleEntries';

const TaskRow = ({ task, onIndentChange }) => (
  <Paper
    elevation={0}
    sx={{
      p: 0.5,
      mb: 1,
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        bgcolor: 'action.hover',
      },
      bgcolor: task.anomaly_type ? 'yellow' : undefined,
    }}
  >
    <Chip label={task.indent_level} variant="outlined" />
    <Box sx={{ width: 16 }} />
    <Box sx={{ width: task.indent_level * 32 }} />

    <Typography sx={{ flexGrow: 1 }}>
      {task.identifier} {task.name} {task.scheduled_start.toString()}{' '}
      {task.scheduled_end.toString()}
    </Typography>

    <Stack direction="row" spacing={1}>
      <Tooltip title="Decrease indent">
        <span>
          <IconButton
            disabled={task.indent_level === 0}
            size="small"
            onClick={() => onIndentChange(task.id, -1)}
          >
            <KeyboardBackspaceIcon />
          </IconButton>
        </span>
      </Tooltip>

      <Tooltip title="Increase indent">
        <IconButton size="small" onClick={() => onIndentChange(task.id, 1)}>
          <KeyboardTabIcon />
        </IconButton>
      </Tooltip>
    </Stack>
  </Paper>
);

export function ConfirmImportWBS() {
  const { projectId, workspaceId, importId } = useParams();
  const { data: importData, isLoading } = useGetImportQuery({ backendImportId: importId });
  const scheduleTable = useScheduleEntries(importData);
  const [tasks, setTasks] = useState<any>([]);
  const [importTasks, { isLoading: isImportingTasks }] = useImportTasksMutation();
  // const table = transformScheduleToTable(importData?.schedule_entries);

  // when we get new data from the api, update our tasks! ✨
  useMemo(() => {
    if (importData?.schedule_entries) {
      setTasks(scheduleTable);
    }
  }, [importData]);

  const handleIndentChange = (taskId, change) => {
    setTasks((prevTasks) =>
      prevTasks.map((task) => {
        if (task.id === taskId) {
          const newLevel = Math.max(0, task.indent_level + change);
          return {
            ...task,
            indent_level: newLevel,
          };
        }
        return task;
      })
    );
  };

  const handleImportTasks = () => {
    // const formattedTask = project?.data?.data.map((task) => {
    //   task.$imported_parent_id = task.parent;

    //   task.status = getTaskStatus(task.progress);

    //   task.work_days = parseFloat(task.duration).toFixed(0);

    //   task.start_date = new Date(task.start_date);
    //   task.end_date = task.$raw.Finish
    //     ? addDays(new Date(task.$raw.Finish), 1)
    //     : gantt.calculateEndDate(task);
    //   task.scheduled_end_date = new Date(task.end_date);

    //   task.forecasted_start_date = new Date(task.start_date);
    //   task.forecasted_end_date = new Date(task.end_date);

    //   if (task.status === 'active' || task.status === 'complete') {
    //     task.actual_start_date = new Date(
    //       task?.$custom_data?.ActualStart ? task.$custom_data.ActualStart : task.start_date
    //     );

    //     if (task.status === 'complete') {
    //       task.actual_end_date = new Date(
    //         task?.$custom_data?.ActualEnd ? task.$custom_data.ActualEnd : task.end_date
    //       );
    //       task.pr = 1;
    //       task.earned_planned_days = task.duration;
    //       task.earned_days = task.duration;
    //     }
    //   }

    //   task.constraint_type = getConstraintType(task.$custom_data.ConstraintType) || 'asap';
    //   task.constraint_date = task.$custom_data.ConstraintDate
    //     ? new Date(task.$custom_data.ConstraintDate)
    //     : null;

    //   return formatGanttTaskToTask(task);
    // });
    const formattedTasks = [];
    importTasks({ projectId, tasks: formattedTasks, dependencies: [] });
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 2, maxWidth: 800, mx: 'auto' }}>
      {/* {JSON.stringify(scheduleTable, null, 2)} */}
      {tasks.map((task) => (
        <TaskRow key={task.id} task={task} onIndentChange={handleIndentChange} />
      ))}
    </Box>
  );
}

// import React, { useState } from 'react';
// import { Box, IconButton, Typography, styled, Tooltip, alpha } from '@mui/material';
// import { ChevronRight, ChevronLeft, Warning, KeyboardArrowRight } from '@mui/icons-material';

// interface Task {
//   id: number;
//   text: string;
//   bounding_box_left: string;
//   indent_level: number;
//   anomaly_type?: any;
//   confidence?: number;
// }

// const TaskContainer = styled(Box)(({ theme }) => ({
//   position: 'relative',
//   '&:hover': {
//     backgroundColor: alpha(theme.palette.action.hover, 0.05),
//     borderRadius: theme.shape.borderRadius,
//   },
// }));

// const IndentControls = styled(Box)(({ theme }) => ({
//   position: 'absolute',
//   left: theme.spacing(1),
//   opacity: 0,
//   transition: theme.transitions.create('opacity'),
//   '.MuiBox-root:hover &': {
//     opacity: 1,
//   },
// }));

// const TreeLine = styled(Box)(({ theme }) => ({
//   width: theme.spacing(3),
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
//   position: 'relative',
//   '&::before': {
//     content: '""',
//     position: 'absolute',
//     top: 0,
//     bottom: 0,
//     width: 1,
//   },
//   '&::after': {
//     content: '""',
//     position: 'absolute',
//     width: theme.spacing(2),
//     height: 1,
//     left: 0,
//   },
// }));

// const TaskContent = styled(Box)({
//   display: 'flex',
//   alignItems: 'center',
//   width: '100%',
// });

// export const ConfirmImportWBS = () => {
//   const [tasks, setTasks] = useState<Task[]>([
//     {
//       id: 1,
//       text: 'Project Planning',
//       indent_level: 0,
//       bounding_box_left: '0',
//       confidence: 0.95,
//     },
//     {
//       id: 2,
//       text: 'Research Phase',
//       indent_level: 1,
//       bounding_box_left: '0.1',
//       anomaly_type: 'unexpected_indent',
//       confidence: 0.85,
//     },
//     {
//       id: 3,
//       text: 'Market Analysis',
//       indent_level: 2,
//       bounding_box_left: '0.2',
//       confidence: 0.92,
//     },
//     {
//       id: 4,
//       text: 'Competitor Research',
//       indent_level: 2,
//       bounding_box_left: '0.4',
//       anomaly_type: 'inconsistent_spacing',
//       confidence: 0.78,
//     },
//   ]);

//   const [expandedTasks, setExpandedTasks] = useState<Set<number>>(new Set(tasks.map((t) => t.id)));

//   const handleIndent = (taskId: number, increase: boolean) => {
//     setTasks((prevTasks) =>
//       prevTasks.map((task) => {
//         if (task.id === taskId) {
//           const newLevel = increase ? task.indent_level + 1 : Math.max(task.indent_level - 1, 0);
//           return {
//             ...task,
//             indent_level: newLevel,
//             bounding_box_left: String(newLevel * 0.1),
//           };
//         }
//         return task;
//       })
//     );
//   };

//   const hasChildren = (taskId: number) => {
//     const taskIndex = tasks.findIndex((t) => t.id === taskId);
//     if (taskIndex === -1 || taskIndex === tasks.length - 1) return false;

//     const currentLevel = tasks[taskIndex].indent_level;
//     const nextLevel = tasks[taskIndex + 1].indent_level;
//     return nextLevel > currentLevel;
//   };

//   const toggleExpand = (taskId: number) => {
//     setExpandedTasks((prev) => {
//       const next = new Set(prev);
//       if (next.has(taskId)) {
//         next.delete(taskId);
//       } else {
//         next.add(taskId);
//       }
//       return next;
//     });
//   };

//   const isVisible = (task: Task) => {
//     let visible = true;
//     for (let i = tasks.indexOf(task) - 1; i >= 0; i--) {
//       if (tasks[i].indent_level < task.indent_level) {
//         if (!expandedTasks.has(tasks[i].id)) {
//           visible = false;
//           break;
//         }
//       }
//     }
//     return visible;
//   };

//   const getAnomalyTooltip = (anomalyType: string) => {
//     switch (anomalyType) {
//       case 'unexpected_indent':
//         return 'Unexpected indentation level based on document structure';
//       case 'inconsistent_spacing':
//         return 'Inconsistent spacing detected between tasks';
//       default:
//         return 'Potential formatting issue detected';
//     }
//   };

//   return (
//     <Box sx={{ maxWidth: 'lg', mx: 'auto', p: 2 }}>
//       {tasks.map((task) => {
//         if (!isVisible(task)) return null;

//         return (
//           <TaskContainer key={task.id}>
//             <Box sx={{ py: 1, px: 2 }}>
//               <IndentControls>
//                 <IconButton
//                   disabled={task.indent_level === 0}
//                   size="small"
//                   onClick={(e) => {
//                     e.stopPropagation();
//                     handleIndent(task.id, false);
//                   }}
//                 >
//                   <ChevronLeft />
//                 </IconButton>
//                 <IconButton
//                   size="small"
//                   onClick={(e) => {
//                     e.stopPropagation();
//                     handleIndent(task.id, true);
//                   }}
//                 >
//                   <ChevronRight />
//                 </IconButton>
//               </IndentControls>

//               <TaskContent sx={{ paddingLeft: 8, ml: 2 * task.indent_level }}>
//                 {/* Caret, Warning Icon, and Task Text */}
//                 <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                   {hasChildren(task.id) ? (
//                     <IconButton
//                       size="small"
//                       sx={{
//                         transform: expandedTasks.has(task.id) ? 'rotate(90deg)' : 'none',
//                         transition: (theme) => theme.transitions.create('transform'),
//                       }}
//                       onClick={(e) => {
//                         e.stopPropagation();
//                         toggleExpand(task.id);
//                       }}
//                     >
//                       <KeyboardArrowRight />
//                     </IconButton>
//                   ) : (
//                     <Box sx={{ width: 28, height: 28 }} /> // Width of IconButton
//                   )}

//                   {task.anomaly_type && (
//                     <Tooltip title={getAnomalyTooltip(task.anomaly_type)}>
//                       <Warning color="warning" sx={{ mr: 1 }} />
//                     </Tooltip>
//                   )}

//                   <Typography variant="body2">
//                     {task.text}
//                     {task.confidence && (
//                       <Typography
//                         color="text.secondary"
//                         component="span"
//                         sx={{ ml: 1 }}
//                         variant="caption"
//                       >
//                         ({(task.confidence * 100).toFixed(0)}%)
//                       </Typography>
//                     )}
//                   </Typography>
//                 </Box>
//               </TaskContent>
//             </Box>
//           </TaskContainer>
//         );
//       })}
//     </Box>
//   );
// };
