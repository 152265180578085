import {
  Container,
  Fab,
  Paper,
  Stack,
  TableContainer,
  TablePagination,
  Toolbar,
  Typography,
  Grid,
} from '@mui/material';
import { Add } from '@mui/icons-material';
import { useMemo, useState } from 'react';
import { ResourceDialog } from '../components/ResourceDialog';
import ResourcesTable from '../components/ResourcesTable';
import {
  useGetAllNonGroupResourcesQuery,
  useGetAllResourceGroupsQuery,
} from '../api/resources.api';
import { ResourceGroups } from '../components/ResourceGroups';
import { useDebounceValue } from 'usehooks-ts';
import SearchBox from 'components/search/SearchBox';

const ResourceIndex = () => {
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(25);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [activeGroupIds, setActiveGroupIds] = useState<number[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useDebounceValue('', 500);
  const [orderBy, setOrderBy] = useState('created_at,desc');

  const {
    data: { data: nonGroupResources = [], meta: { total = 0 } = {} } = {
      data: [],
      meta: { total: 0 },
    },
    isLoading: isLoadingResources,
    isFetching: isFetchingResources,
  } = useGetAllNonGroupResourcesQuery({
    order_by: orderBy,
    per_page: pageSize,
    page: pageNumber,
    q: debouncedSearchQuery,
  });
  const { data: groupResources = [] } = useGetAllResourceGroupsQuery({});

  const filteredResources = useMemo(() => {
    if (!activeGroupIds.length) return nonGroupResources;
    return nonGroupResources.filter((resource) =>
      activeGroupIds.some((groupId) => resource?.parents?.includes(Number(groupId)))
    );
  }, [nonGroupResources, activeGroupIds]);

  const parentResourceMap = useMemo(() => {
    return groupResources.reduce(
      (map, resource) => {
        map[resource.id] = resource.name;
        return map;
      },
      {} as Record<number, string>
    );
  }, [groupResources]);

  const handleGroupToggle = (groupId: number) => {
    setActiveGroupIds((prevActiveGroupIds) => {
      if (prevActiveGroupIds.includes(groupId)) {
        return prevActiveGroupIds.filter((id) => id !== groupId);
      } else {
        return [...prevActiveGroupIds, groupId];
      }
    });
  };

  return (
    <Container maxWidth={false} sx={{ p: { xs: 1, md: 3 }, width: '75%' }}>
      <Typography variant={'h4'}>Resources</Typography>
      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        <Grid item width={{ sm: 1, md: 300 }}>
          <Paper sx={{ p: 2 }}>
            <ResourceGroups
              activeGroupIds={activeGroupIds}
              resourceGroups={groupResources}
              onGroupToggle={handleGroupToggle}
            />
          </Paper>
        </Grid>

        <Grid item xs>
          <TableContainer component={Paper}>
            <Toolbar>
              <Stack
                useFlexGap
                alignItems={'center'}
                direction={'row'}
                justifyContent={'space-between'}
                spacing={1}
                width={1}
              >
                <SearchBox
                  bgcolor={'grey.200'}
                  borderRadius={1}
                  classes={''}
                  fullWidth={false}
                  InputProps={''}
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    setDebouncedSearchQuery(e.target.value);
                  }}
                />
                <Fab
                  color={'secondary'}
                  size={'small'}
                  sx={{ flexShrink: 0, ml: 'auto' }}
                  variant={'extended'}
                  onClick={() => setIsCreateDialogOpen(true)}
                >
                  <Add /> Resource
                </Fab>
                <ResourceDialog
                  handleClose={() => setIsCreateDialogOpen(false)}
                  isGroup={false}
                  open={isCreateDialogOpen}
                />
              </Stack>
            </Toolbar>
            <ResourcesTable
              parentResourceMap={parentResourceMap}
              resources={filteredResources}
              TableProps={{
                defaultOrder: 'asc',
                handleSort: (property, newSortOrder) => setOrderBy(`${property},${newSortOrder}`),
                isFetching: isFetchingResources,
                isLoading: isLoadingResources,
              }}
            />
            {!isLoadingResources && (
              <TablePagination
                component={'div'}
                count={total}
                page={pageNumber - 1}
                rowsPerPage={pageSize}
                rowsPerPageOptions={[1, 25, 50, 100]}
                sx={{ ml: 'auto' }}
                onPageChange={(_, page) => setPageNumber(page + 1)}
                onRowsPerPageChange={(event) => {
                  setPageSize(event.target.value as any);
                  setPageNumber(1);
                }}
              />
            )}
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  );
};

export const resourceRoutes = [{ index: true, element: <ResourceIndex /> }];
