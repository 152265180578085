import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import Box from '@mui/system/Box';

// import Search from '@mui/icons-material/Search';

const Search = styled(({ fullWidth, ...other }) => <Box {...other} />)(({ theme, fullWidth }) => ({
  position: 'relative',
  '&:hover': {
    backgroundColor: (theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: fullWidth === false ? 'auto' : '100%',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(3)})`,
    transition: theme.transitions.create('width'),
    fontSize: '0.9rem',
  },
}));

const SearchBox = ({ placeholder = 'Search', InputProps = {}, classes = {}, onChange, value, ...props }) => {
  return (
    // <Box
    //   fullWidth
    //   component={TextField}
    //   placeholder={'Search...'}
    //   InputProps={{
    //     disableUnderline: true,
    //     startAdornment: (
    //       <InputAdornment sx={{ borderStyle: 'none', padding: '7.5px, 14px' }} position={'start'}>
    //         <SearchIcon style={{ color: '#BDBDBD' }} />
    //       </InputAdornment>
    //     ),
    //   }}
    //   sx={{
    //     '& [class*="MuiInputAdornment-filled"]': { marginTop: '0px !important' },
    //     '& input[class*="MuiFilledInput-input"]': { padding: '7.5px 14px' },
    //     '& input[class*="MuiFilledInput-inputMarginDense"]': { padding: '14.5px 12px' },
    //     '& input[class*="MuiFilledInput-inputAdornedStart"]': { paddingLeft: '0' },
    //     '& input[class*="MuiOutlinedInput"]': { padding: '6.5px 14px', borderStyle: 'none' },
    //     '& [class*="MuiTextField-root"]': { borderStyle: 'none' },
    //   }}
    //   {...props}
    //   label={undefined}
    // />
    <Search backgroundColor={'rgba(255, 255, 255, 0.08)'} {...props}>
      <SearchIconWrapper>
        <SearchIcon sx={{ color: 'grey.600' }} />
      </SearchIconWrapper>
      <StyledInputBase
        inputProps={{ 'aria-label': 'search' }}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        {...InputProps}
      />
    </Search>
  );
};

export default SearchBox;
