import { api } from 'api';
import {
  NewResourceProjectAssignment,
  Resource,
  ResourceRequest,
  ResourceRequestAssignment,
  ResourceRequestStatus,
} from './resources.models';

export const RESOURCE_REQUEST_TAG = 'ResourceRequest';
export const RESOURCE_REQUEST_ASSIGNMENT_TAG = 'ResourceRequestAssignment';

const filterValidParams = (params: Record<string, any>): Record<string, any> => {
  return Object.entries(params).reduce((acc, [key, value]) => {
    if (value !== -1 && value !== '-1' && value !== undefined) {
      acc[key] = value;
    }
    return acc;
  }, {});
};

export const resourceRequestAPI = api
  .enhanceEndpoints({ addTagTypes: [RESOURCE_REQUEST_TAG, RESOURCE_REQUEST_ASSIGNMENT_TAG] })
  .injectEndpoints({
    endpoints: (build) => ({
      getResourceRequest: build.query<
        { data: ResourceRequest[]; meta: any },
        {
          project?: number;
          page?: number;
          per_page?: number;
          trade?: number;
          status?: ResourceRequestStatus | string;
          order_by?: string;
        }
      >({
        query: ({ project, page, per_page, trade, status, order_by }) => ({
          url: `/resource_requests`,
          params: filterValidParams({ project, page, per_page, trade, status, order_by }),
        }),
        providesTags: (result, error) =>
          result ? [{ type: RESOURCE_REQUEST_TAG, id: 'LIST' }] : [],
        transformResponse: (response: { data: ResourceRequest[]; meta: any }) => {
          return {
            data: response.data,
            meta: response.meta,
          };
        },
      }),
      createResourceRequest: build.mutation<
        ResourceRequest,
        { project_id: number; resourceRequest: ResourceRequest }
      >({
        query: ({ project_id, resourceRequest }) => {
          return {
            url: 'resource_requests',
            method: 'POST',
            body: { ...resourceRequest, project_id },
          };
        },
        invalidatesTags: [{ type: RESOURCE_REQUEST_TAG, id: 'LIST' }],
      }),

      updateResourceRequest: build.mutation<ResourceRequest, ResourceRequest>({
        query: (resourceRequest) => ({
          url: `/resource_requests/${resourceRequest.id}`,
          method: 'PATCH',
          body: resourceRequest,
        }),
        invalidatesTags: (result, error, arg) => [
          { type: RESOURCE_REQUEST_TAG, id: arg.id },
          { type: RESOURCE_REQUEST_TAG, id: 'LIST' },
        ],
      }),
      deleteResourceRequest: build.mutation<void, number | string>({
        query: (id) => ({
          url: `resource_requests/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: [{ type: RESOURCE_REQUEST_TAG, id: 'LIST' }],
      }),

      getAvailableResources: build.query<Resource[], { resourceRequestId: number }>({
        query: ({ resourceRequestId }) => ({
          url: `/resource_requests/${resourceRequestId}/available_resources`,
        }),
        providesTags: (result, error) =>
          result ? [{ type: RESOURCE_REQUEST_ASSIGNMENT_TAG, id: 'LIST' }] : [],
        transformResponse: (response: { data: Resource[] }) => response.data,
      }),

      syncResourceAssignments: build.mutation<
        ResourceRequest,
        {
          resourceRequestId: number;
          assignments: (ResourceRequestAssignment | NewResourceProjectAssignment)[];
        }
      >({
        query: ({ resourceRequestId, assignments }) => ({
          url: `/resource_requests/${resourceRequestId}/assignments`,
          method: 'POST',
          body: { assignments },
        }),
        invalidatesTags: (result, error, arg) => [
          { type: RESOURCE_REQUEST_TAG, id: arg.resourceRequestId },
        ],
      }),

      bulkCreateResourceRequestAssignment: build.mutation<
        NewResourceProjectAssignment[],
        { assignments: NewResourceProjectAssignment[] }
      >({
        query: (assignment) => {
          return {
            url: `resource_request_assignments`,
            method: 'POST',
            body: assignment,
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: RESOURCE_REQUEST_TAG, id: arg.assignments[0].resource_request_id },
          { type: RESOURCE_REQUEST_ASSIGNMENT_TAG, id: 'LIST' },
        ],
        transformResponse: (response: { data: NewResourceProjectAssignment[] }) => {
          return response.data;
        },
      }),

      bulkUpdateResourceRequestAssignment: build.mutation<
        NewResourceProjectAssignment,
        { assignments: NewResourceProjectAssignment[] }
      >({
        query: (assignment) => ({
          url: `/resource_request_assignments`,
          method: 'PATCH',
          body: assignment,
        }),
        invalidatesTags: (result, error, arg) => [
          { type: RESOURCE_REQUEST_TAG, id: arg.assignments[0].resource_request_id },
          { type: RESOURCE_REQUEST_ASSIGNMENT_TAG, id: 'LIST' },
        ],
      }),

      bulkDeleteResourceRequestAssignment: build.mutation<
        void,
        { assignments: NewResourceProjectAssignment[] }
      >({
        query: (assignments) => ({
          url: `resource_request_assignments`,
          method: 'DELETE',
          body: assignments,
        }),
        invalidatesTags: [
          { type: RESOURCE_REQUEST_TAG, id: 'LIST' },
          { type: RESOURCE_REQUEST_ASSIGNMENT_TAG, id: 'LIST' },
        ],
      }),
    }),
  });

export const {
  useGetResourceRequestQuery,
  useCreateResourceRequestMutation,
  useUpdateResourceRequestMutation,
  useDeleteResourceRequestMutation,
  useGetAvailableResourcesQuery,
  useSyncResourceAssignmentsMutation,
  useBulkCreateResourceRequestAssignmentMutation,
  useBulkUpdateResourceRequestAssignmentMutation,
  useBulkDeleteResourceRequestAssignmentMutation,
} = resourceRequestAPI;
